<script lang="ts" setup>
import { ref, useSlots } from 'vue'
import Button from '~/components/molecules/Button.vue'

import { InputSize } from '~/src/enums/Input'
import Icon from '~/components/molecules/Icon.vue'
import { hasOwnProperty } from '~/src/utils'
import { ButtonSize, ButtonType } from '~/src/enums/Button'

interface Props {
  type?: string
  disabled?: boolean
  placeholder?: string
  label?: string
  name: string
  inputClass?: string
  modelValue?: string | number | null
  size?: InputSize
  inputmode?: 'text' | 'search' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal'
  required?: boolean
  readonly?: boolean
  min?: number
  max?: number
  autofocus?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  placeholder: '',
  label: '',
  modelValue: '',
  size: InputSize.Medium,
  readonly: false,
  inputmode: 'text',
  autofocus: false,
})

const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'enter'])

const instance = getCurrentInstance()

const validateValue = (value: Props['modelValue']) => {
  if (inputType.value !== 'tel' && inputType.value !== 'number') { return value }
  if (Number.isNaN(props.min) || Number.isNaN(props.max)) { return value }
  if (Number.isNaN(value)) { return value }
  const numeric = Number(value)
  if (numeric < props.min!) { return props.min! }
  if (numeric > props.max!) { return props.max! }
  return numeric
}

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    const validValue = validateValue(value)
    if (validValue === props.modelValue) {
      instance?.proxy?.$forceUpdate()
    }
    emit('update:modelValue', validateValue(value))
  },
})

const input = ref<HTMLInputElement | null>(null)

const slots = useSlots()

const hasPrepend = hasOwnProperty(slots, 'prepend')
const hasAppend = hasOwnProperty(slots, 'append')
const hasPlaceholder = hasOwnProperty(slots, 'placeholder')

const hasBottom = hasOwnProperty(slots, 'bottom')

const showPassword = ref(false)

const inputType = ref<string>(props.type as string || 'text')

const togglePassword = () => {
  showPassword.value = !showPassword.value
  if (showPassword.value) {
    inputType.value = 'text'
  }
  else {
    inputType.value = 'password'
  }
}

const focusInput = async () => {
  await nextTick()
  if (input.value) {
    input.value.focus()
  }
}

const showAppend = computed(() => hasAppend || props.type === 'password')

defineExpose({
  input,
  focusInput,
})

onMounted(() => {
  if (props.autofocus) {
    focusInput()
  }
})
</script>

<template>
  <div class="relative">
    <label
      v-if="label"
      :for="props.name"
      class="block text-input min-w-fit mb-1"
    >{{ label }}</label>
    <div
      class="
        input
        transition
        duration-75
        border
        min-w-fit
        text-textfield
        border-black-10
        rounded
        shadow-small-gray
        bg-white-100
        focus-within:border-black-100
        focus-within:shadow-gray
        grid
        grid-flow-col
        items-center
      "
      :class="{
        [`input--size--${props.size}`]: true,
        'error-input': $slots.error,
        'input--disabled': props.disabled,
        [props.inputClass as string]: true,
        'prepend-input': hasPrepend && !showAppend,
        'full': hasPrepend && showAppend,
        'input-append': !hasPrepend && showAppend,
      }"
    >
      <div
        class="prepend"
        :class="{ hidden: !hasPrepend }"
      >
        <slot name="prepend" />
      </div>
      <div class="relative">
        <input
          :id="props.name"
          ref="input"
          v-model="model"
          :name="props.name"
          :type="inputType"
          :placeholder="hasPlaceholder ? '' : props.placeholder"
          :label="props.label"
          :disabled="props.disabled"
          :inputmode="props.inputmode"
          :required="required"
          :readonly="readonly"
          class="
            rounded
            w-full
            placeholder:text-black-50
            disabled:placeholder:text-black-20
            disabled:text-black-50
            text-black-100
            focus:outline-none
            bg-transparent
            relative
            z-20
          "
          :class="{
            'has-append': showAppend,
          }"
          @focus="emit('focus')"
          @blur="emit('blur')"
          @keyup.enter="emit('enter')"
        >
        <div
          v-if="hasPlaceholder && !modelValue"
          :class="[
            'flex items-center gap-1 absolute top-2 z-10 left-3 text-black-50 select-none',
            {
              'text-black-20': disabled,
            },
          ]"
        >
          <slot name="placeholder" />
        </div>
      </div>
      <!-- TODO: refactor this mess -->
      <div
        class="append"
        :class="{ hidden: !showAppend }"
      >
        <slot name="append">
          <Button
            v-if="props.type === 'password'"
            :size="ButtonSize.Small"
            :type="ButtonType.Link"
            @click="togglePassword"
          >
            <template #icon>
              <Icon
                :name="showPassword ? 'hide' : 'show'"
                stroked
              />
            </template>
          </Button>
        </slot>
      </div>
    </div>
    <div :class="[!hasBottom ? 'hidden' : 'bottom']">
      <slot name="bottom" />
    </div>
    <div
      v-if="$slots.error"
      class="mb-5"
    >
      <p class="error">
        <slot name="error" />
      </p>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.prepend {
  @apply ml-2;
}
.append {
  @apply mr-2;
}
.bottom {
  @apply mt-1;
}
.error {
  @apply absolute text-body-regular-12 text-error h-4 mt-1 text-ellipsis overflow-hidden whitespace-nowrap w-full;

  &-input {
    @apply shadow-small-red border-error;
  }
}

.input {
  grid-template-areas: "input";
  grid-template-columns: auto;
  &.prepend-input {
    grid-template-areas: "prepend input";
    grid-template-columns: min-content auto;
  }
  &.full {
    grid-template-areas: "prepend input append";
    grid-template-columns: min-content auto min-content;
  }
  &.input-append {
    grid-template-areas: "input append";
    grid-template-columns: auto min-content;
  }
  .prepend {
    grid-area: prepend;
  }
  input {
    grid-area: input;
  }
  .append {
    grid-area: append;
  }
  &--size {
    &--medium {
      input {
        @apply py-2 px-3;
        &.has-append {
          @apply pr-2;
        }
      }
    }

    &--small {
      input {
        @apply pl-2 pr-1 py-0.5;
      }
    }
  }
  &--disabled {
    @apply bg-black-1 shadow-transparent;

    input {
      @apply pointer-events-none;
      background-color: inherit;
    }
  }
}
</style>
